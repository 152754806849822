const amountForms = document.querySelectorAll('[data-amount-form]');

amountForms.forEach(amountForm => {
   let amountInput = amountForm.querySelector('input[name="amount"]');

   // Fixes cart amounts with dynamic names
   if (!amountInput) {
      amountInput = amountForm.querySelector('[data-amount-input]');
      const inputForm = amountInput.closest('form');
      const updateCartAmountButton = inputForm.querySelector('[data-amount-update]');

      amountInput.addEventListener('change', () => updateCartAmountButton.click());
   }

   // If user is logged in and amount input exists
   if (amountInput) {
      const min = amountInput.getAttribute('min') ? parseInt(amountInput.getAttribute('min')) : 0;
      const max = amountInput.getAttribute('max') ? parseInt(amountInput.getAttribute('max')) : 99999;
      const step = amountInput.getAttribute('step') ? parseInt(amountInput.getAttribute('step')) : 1;

      const increaseAmountButton = amountForm.querySelector('button[data-amount-increase]');
      const decreaseAmountButton = amountForm.querySelector('button[data-amount-decrease]');

      increaseAmountButton.addEventListener('click', () => {
         const value = parseInt(amountInput.value);
         let newValue = value + step;

         amountInput.value = newValue <= max ? newValue : max;
         amountInput.dispatchEvent(new Event('change'));
      });

      decreaseAmountButton.addEventListener('click', () => {
         const value = parseInt(amountInput.value);
         let newValue = value - step;

         amountInput.value = newValue >= min ? newValue : min;
         amountInput.dispatchEvent(new Event('change'));
      });
   }

});